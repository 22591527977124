import React, {useState, useEffect, useRef} from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";


import { MyVideo } from './Common/ProjectHeader'
import styled from "@emotion/styled";
import Box from "@mui/material/Box/Box";

export const VideoJS = ( props ) => {

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady, imagePath } = props;

  const isDesk = useRef(false);
  const [isMobile, setIsMobile] = useState(true);

  // console.log(isMobile);

  useEffect(() => {
    try {
      isDesk.current = ! /Mobi|Android/i.test(navigator.userAgent);
      setIsMobile(!isDesk.current);
      if(playerRef.current && isDesk.current) {
        playerRef.current.play();
      }
    } catch (e) {
    }
  }, []);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
        if(isDesk.current) {
          playerRef.current.play();
        }
      });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <ImageBack imagePath={imagePath} >
      <div data-vjs-player>
        <MyVideo
          ref={videoRef}
          style={{
            objectFit: 'cover',
            height: '100%',
            width: '100%',
          }}
          className="video-js vjs-default-skin vjs-big-play-centered"
          // autoPlay="autoPlay"
          loop="loop"
          // controls
          muted
        />
      </div>
      {
        isMobile ? (
          <ImageBack
            imagePath={imagePath}
            style={{
              position: 'absolute',
              zIndex: 2,
              top: 0,
            }}
          />
        ) : null
      }
    </ImageBack>
  );
}

export default VideoJS;

const ImageBack = styled(Box)(({theme, imagePath}) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundImage: `url(${imagePath})`,
}));
