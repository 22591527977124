import * as React from 'react'
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import VideoJs from '../Video'
import config from "../../config";
import {BlackBack} from "./Base";

const HeadBox = styled(Box)(({theme}) => {
  return {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '56.25vw',
    minHeight: 420,
    overflow: 'hidden',
  }
});

export const MyVideo = styled('video')(({theme}) => {
  return {
    position: 'relative',
    zIndex: 1,
    marginLeft: 0,
    marginTop: 0,
    width: '100%',
    height: '100%',
    verticalAlign: 'bottom',
  }
})

export const CoverContent = styled(Box)(({theme}) => {
  return {
    top: 0,
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100%',
  }
})

const CenterConatiner = styled(Container)(({theme}) => {
  return {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
})

export default (props) => {
  const { backImg, backVideo, subTitle, title, titleEn, titleEn2, gridShow, topContent, handleNextClick } = props;
  return (
    <HeadBox
      style={
        backImg ? {backgroundImage: `url(${backImg})`} : {}
      }
    >
      {
        backVideo ? (
          <VideoJs
            imagePath={backImg}
            options={{
              controls: false,
              // responsive: true,
              fluid: true,
              // preload: 'auto',
              sources: [{
                src: config.MEDIA_ROOT + backVideo,
                // src: 'https://media.bixuange.com/static/video/index/02.m3u8',
                // src: require('../images/index/02.mp4').default,
                type: 'application/x-mpegURL',
                // type: 'video/mp4'
              }]
            }}
          >
            您的浏览器不支持 video。
          </VideoJs>
          // <MyVideo
          //   src={backVideo}
          //   autoPlay="autoPlay"
          //   loop="loop"
          //   muted
          //   style={{objectFit: 'cover'}}
          // >
          //   您的浏览器不支持 video。
          // </MyVideo>
        ) : null
      }
      {
        gridShow ? (
          <BlackBack />
        ) : null
      }
      <CoverContent>
        <CenterConatiner
          maxWidth="xl"
          sx={{
            p: 2
          }}
        >
          {topContent || (
            <>
              <Typography
                variant="h6"
                align="center"
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '16px',
                    sm: '3vw',
                    lg: '30px'
                  },
                  textShadow: '1px 1px 5px #000',
                }}
              >
                {subTitle || ''}
              </Typography>
              <Typography
                variant="h4"
                align="center"
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '24px',
                    sm: '4.2vw',
                    lg: '42px'
                  },
                  textShadow: '1px 1px 5px #000',
                }}
              >
                {title || ''}
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '14px',
                    sm: '2.4vw',
                    lg: '24px'
                  },
                  textShadow: '1px 1px 5px #000',
                }}
              >
                {titleEn || ''}
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '14px',
                    sm: '2.4vw',
                    lg: '24px'
                  },
                  textShadow: '1px 1px 5px #000',
                }}
              >
                {titleEn2 || ''}
              </Typography>
            </>
          )}
          {
            handleNextClick ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  textAlign: 'center',
                  zIndex: 8,
                }}
              >
                <Box
                  component="img"
                  alt=""
                  onClick={handleNextClick}
                  src={require('../../images/common/icon_down.png').default}
                  sx={{
                    cursor: 'pointer',
                    width: {
                      xs: '35px',
                      sm: '45px',
                      md: '60px'
                    },
                  }}
                />
              </Box>
            ) : null
          }
        </CenterConatiner>
      </CoverContent>
    </HeadBox>
  )
}
